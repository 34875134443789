@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url("./fonts/Gilroy-Regular.ttf");
}
@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url("./fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: url("./fonts/Gilroy-SemiBold.ttf");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  src: url("./fonts/Gilroy-Bold.ttf");
}

html {
  font-family: Gilroy, sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 100% 16px;
  overflow-x: hidden;
  overflow-y: scroll !important;
}

html,
#root,
body,
.App {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #757575;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #4d4d4d;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #5a5a5a;
}
