/* Estilos generales */
body {
  font-family: "Gilroy", sans-serif;
  background-color: #ffffff;
  color: #000000;
  margin: 0;
  padding: 0;
}

h1 {
  color: #000000;
  font-size: 40px;
  margin-bottom: 10px;
  text-align: left;
}

h4 {
  color: #fff;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 20px;
}

p {
  margin-top: 0;
}

@media (width < 768px) {
  h1 {
    font-size: 30px;
  }
  h4 {
    font-size: 20px;
  }
}

/* Layout principal */
.app-container {
  display: flex;
  width: 100%;
}

.main-content {
  margin-left: 100px;
  transition: margin-left 0.3s ease;
  width: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-content.sidebar-open {
  margin-left: 280px;
  width: calc(100% - 300px);
}

@media (width < 768px) {
  .app-container {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    width: auto;
  }
  .main-content {
    width: 100% !important;
    margin: 0 !important;
  }
}

/* Estilos del sidebar */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100% - 40px);
  background-color: #07ea93;
  box-shadow: 0px 4px 21px rgb(39 39 48 / 10%);
  transition: all 0.3s ease;
  z-index: 1000;
  border-radius: 40px;
  margin: 20px;
  padding: 0 30px;
}

.sidebar.open {
  width: 160px;
}

.sidebar:not(.open) {
  width: 50px;
  padding: 0;
}

.toggle-btn {
  padding: 13px 11px;
  background: #06bb76;
  border: none;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: 30px;
  border-radius: 100px;
}

.selectArrow {
  z-index: 1;
  display: block;
  content: "";
  width: 6px;
  height: 6px;
  margin-left: 0.35em;
  border-style: solid;
  border-color: #000;
  border-top-width: 0;
  border-bottom-width: 2px;
  border-left-width: 0;
  border-right-width: 2px;
  transform: rotate(-45deg);
  transition: transform 0.3s ease;
  position: relative;
  right: 4px;
}

.selectArrow.open-arrrow {
  transform: rotate(135deg);
  right: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.sidebar .button {
  padding: 12px;
  border-radius: 10px;
  box-shadow: none;
  color: #fff;
  font-size: 15px;
  position: absolute;
  bottom: 50px;
  background: #06bb76;
  align-self: center;
}

.sidebar .button svg path {
  fill: #fff;
}

.sidebar .logoSide {
  width: 80px;
  margin-bottom: 20px;
  margin-top: 30px;
}

@media (width < 768px) {
  .sidebar {
    position: relative;
    height: auto;
    border-radius: 30px;
    margin: 10px 0;
    padding: 0 15px;
    width: auto !important;
    display: flex;
  }
  .sidebar .logoSide {
    width: 50px;
    margin: 10px 0;
  }
  .toggle-btn {
    display: none;
  }

  .sidebar h4 {
    display: none;
  }

  .sidebar nav {
    display: flex;
  }
  .sidebar ul {
    display: flex;
    gap: 10px;
    margin: 0 10px;
  }

  .sidebar .button {
    padding: 12px;
    font-size: 13px;
    position: relative;
    bottom: inherit;
  }
  .sidebar a {
    font-size: 16px;
  }
  .sidebar li {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
}

/* Estilos del dashboard */
.dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.campaigns-content {
  justify-content: flex-start;
  gap: 20px;
}

/* Estilos de la tabla */

.header-table {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: auto;
  margin-bottom: 20px;
}

.control-buttons {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.control-buttons p {
  margin: 0;
}

.loader-table {
  position: sticky;
  background: #ffffff69;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  backdrop-filter: blur(11px);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-leads.over-hidden {
  overflow: hidden;
}

table .button {
  padding: 5px 25px;
  font-size: 15px;
}

.table-leads {
  width: 100%;
  height: auto;
  border-radius: 20px;
  overflow: scroll;
  border: #fff solid;
  box-shadow: 0px 0px 0px 5px #000;
  position: relative;
}

.table-leads table a {
  font-weight: 600;
  color: inherit;
}

.table-leads table {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  color: #000000;
}

.table-leads table thead {
  background-color: #07ea93;
  position: sticky;
  top: 0;
  z-index: 1;
}
.table-leads table td,
.table-leads table th {
  padding: 5px;
  text-align: left;
}
.table-leads table tr:nth-child(even) {
  background-color: #f1f1f1;
}
.table-leads table thead {
  background-color: #07ea93;
}

@media (width < 768px) {
  .table-leads {
    width: auto;
  }
  .header-table {
    height: auto;
  }
  .header-table h1 {
    margin-top: 0;
  }
}

/* Estilos de los badges de estado */
.status-badge {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8em;
}

.status-success {
  background-color: #07ea93;
  color: #ffffff;
}

.status-pending {
  background-color: #ffdd78;
  color: #000000;
}

/* Botones */

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  gap: 10px;
  background: #07ea93;
  box-shadow: 0 4px 21px #2727301a;
  border-radius: 15px;
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  font-family: "Gilroy";
  border: none;
  transition: background 0.3s ease;
}

.button:hover {
  background: #06bb76;
}

.button[disabled] {
  background: #e0e0e0;
  color: #888888;
  cursor: not-allowed;
}

/* Detalles de lead */

.lead-detail-view {
  padding: 0 20px;
}

.lead-detail-view .table-leads {
  min-height: auto;
  margin-bottom: 30px;
}

/* Estilos de la paginación */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px;
  height: auto;
}

.pagination button {
  background-color: #07ea93;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  margin: 0 10px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Gilroy";
}

.pagination button:disabled {
  background-color: #e0e0e0;
  color: #888888;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
  color: #000000;
}

/* Login  */

.login {
  min-height: 750px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #07ea93;
}

.login .container {
  gap: 20px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login svg {
  width: 100px;
}
.loginDiv {
  background-color: #fff;
  width: 100%;
  padding: 50px 30px;
  border-radius: 20px;
  box-shadow: 0px 10px 50px 0px #0002;
}
.loginDiv .form {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.formSign .button {
  width: 100%;
}
.error {
  background: #ef9a9a;
  color: #b71c1c;
  padding: 2px 15px;
  border-radius: 10px;
}

.form :is(input, textarea, select) {
  border-radius: 20px;
  background: transparent;
  padding: 19px 25px;
  border: none;
  position: relative;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  transition: all 0.4s;
  border: 3px solid #000;
  appearance: none;
  font-family: "Gilroy";
}

.loginDiv h2 {
  margin: 0;
  text-align: center;
  margin-bottom: 20px;
}

/* Media */

@media (max-width: 850px) {
  .loginDiv {
    width: 100%;
    max-width: none;
  }
}

/* Estilos para el gráfico de líneas */
.chart-container {
  width: 100%;
  height: 400px; /* Ajusta la altura según sea necesario */
  margin-bottom: 20px;
}

/* Estilos para date picker */

.rdrDateDisplay * {
  font-family: "Gilroy";
}

.rdrCalendarWrapper {
  position: relative;
  z-index: 5;
}

.rdrDateDisplay {
  min-width: 350px;
  width: fit-content;
}

.rdrDateDisplayWrapper {
  box-shadow: 0 4px 21px #2727301a;
  border-radius: 15px;
  margin-bottom: 10px;
}

.date-range-picker {
  width: fit-content;
}

.date-range-picker :is(.rdrMonthAndYearWrapper, .rdrMonthsVertical) {
  display: none;
}

.date-range-picker.focusDate :is(.rdrMonthAndYearWrapper, .rdrMonthsVertical) {
  display: flex;
  position: absolute;
  background: #fff;
  top: 120px;
  width: 100%;
}

.date-range-picker.focusDate .rdrMonthAndYearWrapper {
  top: 50px;
}

.date-range-picker.focusDate .rdrMonthsVertical {
  top: 110px;
}

.rdrDateDisplayItemActive,
.rdrDayHovered {
  border-color: #07ea93 !important;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background-color: #07ea93 !important;
  color: #fff;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: #07ea93 !important;
}

/* Arrow Sort */
.table-leads table th a {
  font-size: 13px;
  margin-left: 10px;
  text-decoration: none;
}

/* Search Form */

form.search-form {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

form.search-form :is(input, select),
.inputMango {
  border-radius: 20px;
  padding: 19px 65px 19px 20px;
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  transition: all 0.4s;
  border: 3px solid #000;
  appearance: none;
  width: fit-content;
}

.selector {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.35em;
}

.selectArrowForm {
  position: absolute;
  right: 30px;
  z-index: 1;
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  margin-left: 0.35em;
  border-style: solid;
  border-color: var(--darkcolor);
  border-top-width: 0;
  border-bottom-width: 3px;
  border-left-width: 0;
  border-right-width: 3px;
  transform: rotate(45deg);
  pointer-events: none;
}

/* Modal */

.modal-overlay {
  position: fixed;
  z-index: 99999;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #00291966;
  left: 0;
  top: 0;
}

.modal-content {
  background: #fff;
  border-radius: 20px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
}

.form-actions {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content h2 {
  margin-top: 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.modal-content .button.cancel {
  background: #eb6969;
}
